import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchWidgets = createAsyncThunk(
    "data/widgets",
    async () => {
        if (window.gregario.skipFeature.includes("data/widgets")) {
            return
        }
        const data = await window.gregario.getWidgets()
        window.gregario.globalData.Widgets = data?.results
        return data
    }
);

export const widgets = createSlice({
    name: "widgets",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchWidgets.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchWidgets.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
        })
        builder.addCase(fetchWidgets.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default widgets.reducer