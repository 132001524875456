import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchCountryComponent = createAsyncThunk(
    "data/countryComponent",
    async () => {
        if (window.gregario.skipFeature.includes("data/countryComponent")) {
            return
        }
        const country = window.gregario?.globalData?.country?.length && window.gregario.globalData.country[0]
        if (country) {
            const data = await window.gregario.getCountryComponents(null, {
                'counrty': country.id
            })
            window.gregario.globalData.countryComponents = data?.results
            return data
        }
    }
);

export const countryComponentSlice = createSlice({
    name: "countryComponent",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCountryComponent.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchCountryComponent.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
        })
        builder.addCase(fetchCountryComponent.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default countryComponentSlice.reducer