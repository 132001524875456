import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {state: 'idle', error:null, response:{}}

export const fetchBuilderCompany = createAsyncThunk(
    "data/builderCompany",
    async () => {
        if (window.gregario.skipFeature.includes("data/builderCompany")) {
            return
        }
        const data = await window.gregario.builderCompany(null, {'realestate_isnull':false})
        return data
    }
)

export const builderCompanySlice = createSlice({
    name: "builderCompany",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchBuilderCompany.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchBuilderCompany.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
            if (!state.response?.results) {
                state.selectedOptions = []
            }
            state.selectedOptions = state.response?.results?.map((item) => ({
                value: item.id, label: item.name, color: item.color ? item.color : '#000000'
            }))
        })
        builder.addCase(fetchBuilderCompany.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    }
})

export default builderCompanySlice.reducer