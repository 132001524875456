import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchRelevanceCalculations = createAsyncThunk(
    "data/relevanceCalculations",
    async () => {
        if (window.gregario.skipFeature.includes("data/relevanceCalculations")) {
            return
        }
        const data = await window.gregario.getRelevanceCalculations()
        window.gregario.globalData.RelevanceCalculations = data?.results
        return data
    }
);

export const relevanceCalculationsSlice = createSlice({
    name: "relevanceCalculations",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchRelevanceCalculations.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchRelevanceCalculations.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
            if (!state.response?.results) {
                state.selectedOptions = []
            }
            state.selectedOptions = state.response?.results?.map((item) => ({
                value: item.id, label: item.name, color: item.color ? item.color : '#000000'
            }))
        })
        builder.addCase(fetchRelevanceCalculations.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default relevanceCalculationsSlice.reducer