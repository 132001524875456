import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {state: 'idle', error:null, response:{}}

export const fetchRealEstateCompany = createAsyncThunk(
    "data/realEstateCompany",
    async () => {
        if (window.gregario.skipFeature.includes("data/realEstateCompany")) {
            return
        }
        const data = await window.gregario.realEstateCompany(null, {'realestate_isnull':false})
        return data
    }
)

export const realEstateCompanySlice = createSlice({
    name: "realEstateCompany",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchRealEstateCompany.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchRealEstateCompany.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
            if (!state.response?.results) {
                state.selectedOptions = []
            }
            state.selectedOptions = state.response?.results?.map((item) => ({
                value: item.id, label: item.name, color: item.color ? item.color : '#000000'
            }))
        })
        builder.addCase(fetchRealEstateCompany.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    }
})

export default realEstateCompanySlice.reducer