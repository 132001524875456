import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchRestaurantTypeCustom = createAsyncThunk(
    "data/restaurantTypeCustom",
    async () => {
        if (window.gregario.skipFeature.includes("data/restaurantTypeCustom")) {
            return
        }
        const data = await window.gregario.getRestaurantTypeCustom(null, {'restaurant_type_custom_isnull':false})
        window.gregario.globalData.restaurantTypeCustom = data?.results
        return data
    }
);

export const restaurantTypeCustomSlice = createSlice({
    name: "restaurantTypeCustom",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchRestaurantTypeCustom.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchRestaurantTypeCustom.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
            if (!state.response?.results) {
                state.selectedOptions = []
            }
            state.selectedOptions = state.response?.results?.map((item) => ({
                value: item.id, label: item.name, color: item.color ? item.color : '#000000', machine_name: item.machine_name
            })) || []
        })
        builder.addCase(fetchRestaurantTypeCustom.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default restaurantTypeCustomSlice.reducer