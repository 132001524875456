import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchOpportunityTagList = createAsyncThunk(
    "data/opportunityTagList",
    async () => {
        if (window.gregario.skipFeature.includes("data/opportunityTagList")) {
            return
        }
        const data = await window.gregario.getOpportunityTagList()
        window.gregario.globalData.OpportunityTagList = data?.results
        return data
    }
);

export const opportunityTagListSlice = createSlice({
    name: "opportunityTagList",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchOpportunityTagList.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchOpportunityTagList.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
            if (!state.response?.results) {
                state.selectedOptions = []
            }
            state.selectedOptions = state.response?.results?.map((item) => ({
                value: item.id, label: item.name, color: item.color ? item.color : '#000000'
            }))
        })
        builder.addCase(fetchOpportunityTagList.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default opportunityTagListSlice.reducer