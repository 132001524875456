import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchSaleSummaryMetrics = createAsyncThunk(
    "data/saleSummaryMetrics",
    async () => {
        if (window.gregario.skipFeature.includes("data/saleSummaryMetrics")) {
            return
        }
        const data = await window.gregario.getSaleSummaryMetrics()
        window.gregario.globalData.SaleSummaryMetrics = data?.results
        return data
    }
);

export const saleSummaryMetrics = createSlice({
    name: "saleSummaryMetrics",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchSaleSummaryMetrics.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchSaleSummaryMetrics.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
            if (!state.response?.results) {
                state.selectedOptions = []
            }
            state.selectedOptions = state.response?.results?.map((item) => ({
                value: item.id, label: item.name, color: item.color ? item.color : '#000000'
            }))
        })
        builder.addCase(fetchSaleSummaryMetrics.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            console.log("error", action.error.message)
        })
    },
});

export default saleSummaryMetrics.reducer