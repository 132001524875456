import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchDataViews = createAsyncThunk(
    "data/dataViews",
    async () => {
        if (window.gregario.skipFeature.includes("data/dataViews")) {
            return
        }
        const data = await window.gregario.getDataViews()
        // window.gregario.globalData.DataViews = data?.results
        return data
    }
);

export const fetchDataViewMetrics = createAsyncThunk(
    "data/dataViewMetrics",
    async () => {
        if (window.gregario.skipFeature.includes("data/dataViewMetrics")) {
            return
        }
        const data = await window.gregario.getDataViewMetrics()
        // window.gregario.globalData.DataViewMetrics = data?.results
        return data
    }
);

export const getCombinedDataViews = createAsyncThunk(
    "data/dataView",
    async (metrics, thunkAPI) => {
        if (window.gregario.skipFeature.includes("data/dataView")) {
            return
        }
        const dataViews = await thunkAPI.dispatch(fetchDataViews()).unwrap();
        const dataViewMetrics = await thunkAPI.dispatch(fetchDataViewMetrics()).unwrap();

        const data = { results: [] }
        data.results = dataViews?.results.map((dataView) => {
            return {
                ...dataView,
                metrics: (dataViewMetrics?.results || []).filter(
                    dataViewMetric => dataViewMetric.data_view === dataView.id
                ).map(dataViewMetric => {
                    dataViewMetric.metricId = dataViewMetric.metric
                    dataViewMetric.metric = metrics?.find(metric => metric.id === dataViewMetric.metricId)
                    return dataViewMetric
                })
            };
        });

        window.gregario.globalData.DataView = data?.results
        return data
    }
);

export const dataViews = createSlice({
    name: "dataViews",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCombinedDataViews.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(getCombinedDataViews.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
        })
        builder.addCase(getCombinedDataViews.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default dataViews.reducer