import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const fetchStats = createAsyncThunk(
    "data/stats",
    async (opt) => {
        const response = await opt.countCallback()
        return {
            name: opt.id,
            stats: response
        }
    }
);

export const statSlice = createSlice({
    name: "stats",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchStats.fulfilled, (state, action) => {
            if (action.payload.name) {
                state[action.payload.name] = action.payload.stats
            }
        })
    },
});

export const stats = (state) => state

export default statSlice.reducer