import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchFeatures = createAsyncThunk(
    "data/features",
    async () => {
        if (window.gregario.skipFeature.includes("data/features")) {
            return
        }
        let result = []
        let response = null
        let page=1
        do {
            response = await window.gregario.getFeatures(null, {page: page})
            result = [...result, ...(response?.results || [])]
            page = response.current_page + 1
        } while (response && response.current_page < response.pages)
        window.gregario.globalData.Features = result
        response.results = result
        return response
    }
);

export const featuresSlice = createSlice({
    name: "features",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchFeatures.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchFeatures.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
            if (!state.response?.results) {
                state.selectedOptions = []
            }
            state.selectedOptions = state.response?.results?.map((item) => ({
                value: item.id, label: item.name, color: item.color ? item.color : '#000000'
            }))
        })
        builder.addCase(fetchFeatures.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default featuresSlice.reducer