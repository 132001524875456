import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchSubAdministrativeArea = createAsyncThunk(
    "data/subAdministrativeArea",
    async () => {
        if (window.gregario.skipFeature.includes("data/subAdministrativeArea")) {
            return
        }
        const data = await window.gregario.getSubAdministrativeAreas(null, {'object_isnull':false})
        window.gregario.globalData.subAdministrativeArea = data?.results
        return data
    }
);

export const subAdministrativeAreaSlice = createSlice({
    name: "subAdministrativeArea",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchSubAdministrativeArea.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchSubAdministrativeArea.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
            if (!state.response?.results) {
                state.selectedOptions = []
            }
            state.selectedOptions = state.response?.results?.map((item) => ({
                value: item.id, label: item.name, color: item.color ? item.color : '#000000'
            })) || []
        })
        builder.addCase(fetchSubAdministrativeArea.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default subAdministrativeAreaSlice.reducer