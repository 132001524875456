import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchUsers = createAsyncThunk(
    "data/users",
    async () => {
        if (window.gregario.skipFeature.includes("data/users")) {
            return
        }
        const data = await window.gregario.getUsersList(null, {'is_client':true})
        window.gregario.globalData.users = data?.results ? data?.results : data
        return data
    }
);

export const usersSlice = createSlice({
    name: "users",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUsers.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchUsers.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
            if (!state.response?.results) {
                state.selectedOptions = []
            }
            state.selectedOptions = (state.response?.results && state.response.results.length &&
                state.response.results.map((item) => ({
                    value: item.id, label: item.username, color: item.color ? item.color : '#000000'
                }))) || []
        })
        builder.addCase(fetchUsers.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default usersSlice.reducer