import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchUsersResponse = createAsyncThunk(
    "data/usersResponse",
    async () => {
        if (window.gregario.skipFeature.includes("data/usersResponse")) {
            return
        }
        const data = await window.gregario.getUsersList(null, {'is_client':true, 'quiz_response_isnull': false})
        window.gregario.globalData.usersResponse = data?.results
        return data
    }
);

export const usersResponseSlice = createSlice({
    name: "usersResponse",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUsersResponse.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchUsersResponse.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
            if (!state.response?.results) {
                state.selectedOptions = []
            }
            state.selectedOptions = (state.response && state.response.length && state.response.map((item) => ({
                value: item.id, label: item.username, color: item.color ? item.color : '#000000'
            }))) || []
        })
        builder.addCase(fetchUsersResponse.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default usersResponseSlice.reducer