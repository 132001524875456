class UserTracking{
    constructor(data){
        this.data = data
    }

    send(){
        window.gregario.sendTracking(this.data.type, this.data.data)
    }

}

export { UserTracking }
