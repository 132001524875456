import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchZone = createAsyncThunk(
    "data/zone",
    async () => {
        if (window.gregario.skipFeature.includes("data/zone")) {
            return
        }
        const data = await window.gregario.getZones(null, {})
        window.gregario.globalData.zone = data?.results
        return data
    }
);

export const zoneSlice = createSlice({
    name: "zone",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchZone.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchZone.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
            if (!state.response?.results) {
                state.selectedOptions = []
            }
            state.selectedOptions = state.response?.results?.map((item) => ({
                value: item.id, label: item.name, color: item.color ? item.color : '#000000'
            })) || []
        })
        builder.addCase(fetchZone.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default zoneSlice.reducer